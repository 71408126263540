<template>
	<div class="row">
		<div class="col-12 col-xl-12">
			<booking-history-table
				:user-id="userId"
				type="cancelled"
				></booking-history-table>
		</div>
	</div>
</template>

<script>
import BookingHistoryTable from '@/components/modules/bookings/BookingHistoryTable.vue'
export default {
  name: 'PastBookings',
  components: { BookingHistoryTable },
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  }
}
</script>

<style scoped>

</style>
